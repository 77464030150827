<template>
  <b-card
    v-if="data"
    class="earnings-card"
  >
    <b-row>
      <b-col cols="6">
        <small class="text-muted">{{ data.course.subject[$i18n.locale] }}</small>
        <h4 class="mb-1">
          {{ data.course.title }}
        </h4>
        <b-card-text class="text-muted font-small-2">
          <span class="font-weight-bolder"><span> Количество оставшихся дней: </span> {{ dataBetween(data.access_start_date, data.access_end_date) }}</span>
        </b-card-text>
      </b-col>
      <b-col
        cols="6"
        class="d-flex justify-content-end"
      >
        <!-- chart -->
        <vue-apex-charts
          height="120"
          :options="earningsChart.chartOptions"
          :series="[0, 100]"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BCardText,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'

const $earningsStrokeColor2 = '#28c76f66'
// const $earningsStrokeColor3 = '#28c76f33'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BCardText,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // donutChart: {
      //   series: [1, 99],
      //   chartOptions: {
      //     chart: {
      //       type: 'donut',
      //       toolbar: {
      //         show: false,
      //       },
      //     },
      //     legend: {
      //       show: false,
      //     },
      //     colors: [$themeColors.success, $earningsStrokeColor2],
      //     dataLabels: {
      //       enabled: false,
      //     },
      //     comparedResult: [2, -3, 8],
      //     labels: ['Выполнено', 'Не Выполнено'],
      //     stroke: { width: 0 },
      //     grid: {
      //       padding: {
      //         right: -20,
      //         bottom: -8,
      //         left: -20,
      //       },
      //     },
      //     plotOptions: {
      //       pie: {
      //         donut: {
      //           labels: {
      //             show: true,
      //             name: {
      //               fontSize: '2rem',
      //             },
      //             value: {
      //               fontSize: '1rem',
      //               offsetY: 15,
      //               formatter(val) {
      //                 // eslint-disable-next-line radix
      //                 return `${parseInt(val)}%`
      //               },
      //             },
      //             total: {
      //               show: true,
      //               offsetY: -15,
      //               fontSize: '1.5rem',
      //               label: 'Выполнено',
      //               formatter(val) {
      //                 return `${val.config.series[0]}%`
      //               },
      //             },
      //           },
      //         },
      //       },
      //     },
      //     // labels: ['Operational', 'Networking', 'Hiring', 'R&D'],
      //     responsive: [
      //       {
      //         breakpoint: 992,
      //         options: {
      //           chart: {
      //             height: 380,
      //           },
      //           legend: {
      //             position: 'bottom',
      //           },
      //         },
      //       },
      //       {
      //         breakpoint: 576,
      //         options: {
      //           chart: {
      //             height: 320,
      //           },
      //           plotOptions: {
      //             pie: {
      //               donut: {
      //                 labels: {
      //                   show: true,
      //                   name: {
      //                     fontSize: '1.5rem',
      //                   },
      //                   value: {
      //                     fontSize: '1rem',
      //                   },
      //                   total: {
      //                     fontSize: '1.5rem',
      //                   },
      //                 },
      //               },
      //             },
      //           },
      //         },
      //       },
      //     ],
      //   },
      // },

      earningsChart: {
        chartOptions: {
          chart: {
            type: 'donut',
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },
          comparedResult: [2, -3, 8],
          labels: ['Выполнено', 'Не Выполнено'],
          stroke: { width: 0 },
          colors: [$themeColors.success, $earningsStrokeColor2],
          grid: {
            padding: {
              right: -20,
              bottom: -8,
              left: -20,
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    offsetY: 15,
                  },
                  value: {
                    offsetY: -15,
                    formatter(val) {
                    // eslint-disable-next-line radix
                      return `${parseInt(val)}%`
                    },
                  },
                  total: {
                    show: true,
                    offsetY: 15,
                    label: 'Выполнено',
                    formatter(val) {
                      return `${val.config.series[0]}%`
                    },
                  },
                },
              },
            },
          },
          responsive: [
            {
              breakpoint: 1325,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 1200,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
            {
              breakpoint: 1045,
              options: {
                chart: {
                  height: 100,
                },
              },
            },
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 120,
                },
              },
            },
          ],
        },
      },
    }
  },
  methods: {
    dataBetween(start, end) {
      const startDate = new Date(start)

      const endDate = new Date(end)

      const diffTime = Math.abs(endDate - startDate)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
      return diffDays
    },
  },
}
</script>
